import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, CloseButton, Form } from 'react-bootstrap';
import BottomNav from '../BottomNav';
import { useTheme } from '../../ThemeContext';
import { useAuth } from '../AuthContext'; // Импортируй свой AuthContext
import { PlusCircle } from 'react-bootstrap-icons';

const WaitingListPage = () => {
  const { theme } = useTheme();
  const {
    userInfo,
    isAuthenticated,
    isLoading,
    createTrackingItem,
    updateTrackingItem,
    getTrackingList,
    deleteTrackingItem,
    parseTrackingItem,
    trackingItems
  } = useAuth();

  const [logOutput, setLogOutput] = useState('');
  const [trackingList, setTrackingList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';

  const headerStyle = {
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: '0.5rem',
  };

  const handleAddItem = async () => {
    if (!/^[0-9]+$/.test(inputValue)) {
      setErrorMessage('Артикул должен содержать только цифры.');
      return;
    }

    try {
      await parseTrackingItem(inputValue);
      setShowModal(false);
      setShowSuccessModal(true);
      setInputValue('');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Ошибка при добавлении артикула: ' + (error.message || 'Попробуйте снова.'));
    }
  };

  const handlePasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setInputValue(text);
    } catch (error) {
      setErrorMessage('Не удалось вставить из буфера обмена.');
    }
  };

  if (userInfo.user_id !== 7017255909) {
    return <div className={themeClass}>Скоро будет, уже работаем на полную</div>;
  }

  // Загружаем список отслеживаемых товаров при монтировании компонента
  useEffect(() => {
    const fetchTrackingList = async () => {
      if (isAuthenticated && !isLoading) {
        getTrackingList();
      }
    };

    fetchTrackingList();
  }, []);


  // Пока userInfo не загрузилось, можно показывать "загрузка..."


  // Если это тот самый user_id, рендерим "особый" вариант
  if (userInfo.user_id === 7017255909) {
    return (
      <>
        <Container className={`pt-3 pb-5 ${themeClass}`}>
          <Row className="mb-3">
            <Col>
              <div className={`profile-header rounded ${themeClass} shadow-lg`} style={headerStyle}>
                <h1 className="text-center">
                  Лист Отслеживания 🛒
                </h1>
              </div>
            </Col>
          </Row>

          {/* Тут в будущем будет блок отображения списка товаров trackingItems */}
          {trackingItems.length > 0 && (<Row className="mb-3">
            <Col>
              <div className={`d-flex rounded ${themeClass} shadow-lg`} style={headerStyle}>
                {
                  /* 
                    Пример (заготовка) рендера списка:
                    trackingItems.map((item, index) => (
                      <div key={index}>{item.title}</div>
                    ))
                  */
                }
              </div>
            </Col>
          </Row>)}

          {/* Проверяем, сколько товаров в списке, и если < 30, показываем кнопку добавления */}
          {trackingItems.length < 30 && (
            <Row className="mb-3">
              <Col>
                <div
                  className={`d-flex align-items-center justify-content-center gap-2 rounded p-3 shadow-lg`}
                  style={{
                    cursor: 'pointer',
                    border: theme === 'dark' ? '1px solid #444' : '1px solid #ddd',
                    backgroundColor: theme === 'dark' ? '#333' : '#f8f9fa',
                    color: theme === 'dark' ? '#fff' : '#000',
                    fontSize: '1.25rem',
                    fontWeight: '500',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = theme === 'dark' ? '#444' : '#e9ecef';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = theme === 'dark' ? '#333' : '#f8f9fa';
                  }}
                  onClick={() => setShowModal(true)}
                >
                  <span>Добавить товар</span>
                  <PlusCircle size={24} color={theme === 'dark' ? '#fff' : '#007bff'} />
                </div>
              </Col>
            </Row>
          )}

        </Container>

        {/* Модалка для добавления нового товара */}
        <Modal show={showModal} onHide={() => setShowModal(false)} className={theme === 'dark' ? 'text-white modal-dark' : 'text-dark modal-light'}>
          <Modal.Header className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-secondary bg-light text-black'}>
            <Modal.Title>Добавить новый товар</Modal.Title>
            <CloseButton onClick={() => setShowModal(false)} className={theme === 'dark' ? 'btn-close-white' : 'btn-close-black'} />
          </Modal.Header>
          <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'text-black bg-light'}>
            <Form>
              <Form.Group controlId="trackingItemInput">
                <Form.Label>Введите артикул</Form.Label>
                <Form.Control
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Только цифры"
                  isInvalid={!!errorMessage}
                />
                <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-between mt-3">
              <Button variant="secondary" onClick={handlePasteFromClipboard}>
                Вставить из буфера
              </Button>
              <Button variant="primary" onClick={handleAddItem}>
                Добавить
              </Button>
            </div>
          </Modal.Body>

        </Modal>
        {/* Модалка успешного добавления */}
        <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Товар добавлен</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Товар успешно добавлен в список отслеживания!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
              Ок
            </Button>
          </Modal.Footer>
        </Modal>

        <BottomNav />
      </>
    );
  }


};

export default WaitingListPage;
