import { useCallback, useEffect, useRef, useState } from 'react';


/**
 * Кастомный хук для управления скроллом в SalesFeed.
 *
 * @param {Object}   params
 * @param {Array}    params.sales            - Массив товаров/сообщений
 * @param {Function} params.fetchMoreSales   - Функция для подгрузки данных
 * @param {string}   params.type             - Тип ('ws' или другой)
 * @param {number}   params.targetArticle    - Артикул, к которому надо проскроллить
 * @param {Array}    params.columnCount      - Массив вида [кол-во колонок, ширина колонки]
 * @param {number}   params.rowHeight        - Высота одной «строки»/карточки (по умолчанию 430)
 */
export function useSalesFeedScrolling({
    sales,
    fetchMoreSales,
    type,
    targetArticle,
    columnCount,
    rowHeight = 430
}) {
    const listRef = useRef(null);
    const salesRef = useRef(sales);
    // Локальные стейты
    const [unseenCount, setUnseenCount] = useState(0);      // Количество новых непрочитанных «товаров/сообщений»
    const [isAutoScrolling, setIsAutoScrolling] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [prevScrollHeight, setPrevScrollHeight] = useState(0);
    const [salesLength, setSalesLength] = useState(0);
    const [scrolledToArticle, setScrolledToArticle] = useState(false);
    const [pendingScrollId, setPendingScrollId] = useState(null);

    // Плавная прокрутка
    const smoothScrollTo = useCallback((target) => {
        if (!listRef.current) return;

        const startPosition = listRef.current.state.scrollTop;
        const delta = target - startPosition;
        const duration = 300;
        let start = null;

        function step(timestamp) {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            const progressPercentage = Math.min(progress / duration, 1);

            listRef.current.scrollTo({
                scrollTop: startPosition + delta * progressPercentage,
                behavior: 'auto'
            });

            if (progress < duration) {
                requestAnimationFrame(step);
            }
        }

        requestAnimationFrame(step);
    }, []);

    // Скролл к конкретному артикулу (если передан targetArticle)
    const scrollToTargetArticle = useCallback(() => {
        if (!listRef.current || !targetArticle || scrolledToArticle || sales.length === 0) return;

        const parsedArticle = Number(targetArticle);
        const index = sales.findIndex(item => item.article === parsedArticle);
        if (index === -1) return;

        // высчитываем, в какую «строку» попадает нужный индекс
        const rowIndex = Math.floor(index / columnCount[0]);
        const scrollTop = rowIndex * rowHeight;


        // Скроллим плавно к нужному месту
        setTimeout(() => {
            smoothScrollTo(scrollTop);
        }, 0);
        setScrolledToArticle(true);
    }, [targetArticle, scrolledToArticle, sales, columnCount, rowHeight, smoothScrollTo]);

    // Сохранить ID самого верхнего видимого элемента
    function getFirstVisibleSaleId(sales) {
        const { scrollTop } = listRef.current.state;
        const firstVisibleRow = Math.floor(scrollTop / rowHeight);
        const firstVisibleIndex = firstVisibleRow * columnCount[0];


        if (firstVisibleIndex < sales.length) {
            const firstVisibleSale = sales[firstVisibleIndex];
            if (firstVisibleSale?.id) {
                localStorage.setItem('salesFeedScrollPosition', firstVisibleSale.id)

            }
        }
    }

    // Основной колбэк при прокрутке
    const handleScroll = useCallback(() => {
        if (!listRef.current) return;

        const { scrollTop } = listRef.current.state;
        const scrollHeight = listRef.current.props.rowCount * listRef.current.props.rowHeight;
        const clientHeight = listRef.current.props.height;
        const totalScroll = scrollHeight - scrollTop - clientHeight; // до нижней границы


        // Если мы приблизились к непросмотренным, уменьшаем unseenCount
        const newMessagesHeight = unseenCount * listRef.current.props.rowHeight;
        if (totalScroll <= newMessagesHeight) {
            setUnseenCount((prevCount) => {
                const newVal = Math.max(prevCount - 1, 0);

                return newVal;
            });
        }

        // Проверяем, долистали ли до самого низа
        if (scrollTop + clientHeight >= scrollHeight) {
            setIsAutoScrolling(true);
            setUnseenCount(0);
        } else {
            setIsAutoScrolling(false);
        }

        // Подгрузка товаров при прокрутке вверх, если мы достаточно близко к верху
        if (scrollTop <= 2000 && !isLoading && sales.length > 0 && type === 'ws') {
            const firstId = sales[0]?.id;
            setIsLoading(true);

            const currentScrollHeight = listRef.current.props.rowCount * listRef.current.props.rowHeight;
            setPrevScrollHeight(currentScrollHeight);

            fetchMoreSales(firstId);
        }
    }, [unseenCount, isLoading, sales, type, fetchMoreSales]);

    // Автоскролл вниз при изменении sales
    useEffect(() => {
        setSalesLength(sales.length);


        if (sales.length > 0 && isAutoScrolling && listRef.current) {
            listRef.current.scrollToItem({
                align: 'end',
                columnIndex: 0,
                rowIndex: sales.length - 1
            });
        } else {
            // Иначе увеличиваем счётчик непрочитанных
            if (sales.length > salesLength) {
                setUnseenCount((prev) => {
                    const newVal = prev + 1;
                    return newVal;
                });
            }
        }
    }, [sales, isAutoScrolling, salesLength]);

    // Скролл к нужному артикулу при обновлении sales
    useEffect(() => {
        scrollToTargetArticle();
    }, [sales, scrollToTargetArticle]);

    const jumpScrollTo = useCallback((scrollTop) => {
        if (!listRef.current) return;

        listRef.current.scrollTo({
            scrollLeft: 0,
            scrollTop,
            behavior: 'auto' // без анимации
        });
    }, []);

    const findIndexById = useCallback(
        (id) => {
            return sales.findIndex((item) => item.id === id);
        },
        [sales]
    );

    const scrollToId = useCallback(
        (id) => {
            if (!listRef.current) {
                return;
            }
            const idx = findIndexById(id);
            if (idx < 0) {
                return;
            }
            const rowIndex = Math.floor(idx / columnCount[0]);
            const newScrollTop = rowIndex * rowHeight;
            jumpScrollTo(newScrollTop);
        },
        [findIndexById, columnCount, rowHeight, jumpScrollTo]
    );


    // Обработка изменения высоты списка после подгрузки
    useEffect(() => {
        salesRef.current = sales;
        if (prevScrollHeight > 0) {
            const newScrollHeight = listRef.current.props.rowCount * listRef.current.props.rowHeight;


            const scrollDifference = newScrollHeight - prevScrollHeight;
            const currentScrollTop = listRef.current.state.scrollTop;

            // Сохранение текущей позиции скролла
            const scrollTopAfterLoad = currentScrollTop + scrollDifference;

            listRef.current.scrollTo({
                scrollTop: scrollTopAfterLoad,
                behavior: 'auto',
            });

            setPrevScrollHeight(0); // Сброс предыдущей высоты списка
            setIsLoading(false);
        }
    }, [sales]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                console.log(`length ${salesRef.current.length}`)
                getFirstVisibleSaleId(salesRef.current);
            } else {
                const storedId = localStorage.getItem('salesFeedScrollPosition');
                if (storedId) {
                    console.log(`storedId is type "${typeof storedId}" with value:`, storedId);
                    const parsedId = parseInt(storedId, 10); // преобразуем, но без переобъявления
                    if (!isNaN(parsedId)) {
                        setIsAutoScrolling(false);
                        setPendingScrollId(parsedId);
                    }
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    // Дожидаемся, когда нужный ID появится в sales, и тогда прокручиваем
    useEffect(() => {
        if (!pendingScrollId) return; // нечего искать
        const idx = findIndexById(pendingScrollId);
        if (idx >= 0) {
            scrollToId(pendingScrollId);
            setPendingScrollId(null);
        } else {
        }
    }, [pendingScrollId, sales, scrollToId, findIndexById]);

    // Прокрутка к самому новому сообщению или к последнему
    const scrollToNewestMessage = useCallback(() => {
        if (!listRef.current) return;

        if (unseenCount > 0) {
            const newMessageIndex = sales.length - unseenCount;
            const newMessageRow = Math.floor(newMessageIndex / columnCount[0]);
            const targetScrollTop = newMessageRow * listRef.current.props.rowHeight;
            smoothScrollTo(targetScrollTop);
        } else {
            const lastRow = Math.ceil(sales.length / columnCount[0]) - 1;
            const targetScrollTop = lastRow * listRef.current.props.rowHeight;
            smoothScrollTo(targetScrollTop);
        }
    }, [unseenCount, sales, columnCount, smoothScrollTo]);

    return {
        listRef,
        unseenCount,
        handleScroll,
        scrollToNewestMessage,
        isAutoScrolling
    };
}
