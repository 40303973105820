import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from '../ThemeContext';
import { Card, Button, Modal, Carousel, Badge } from 'react-bootstrap';
import { AlignCenter, Star, StarFill } from 'react-bootstrap-icons';
import Lottie from 'lottie-react';
import scrollDownAnimation from '../assets/downButton.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useWebSocket } from './WebSocketContext';
import { FixedSizeGrid as Grid } from 'react-window';
import Codee from './ui/Codee';
import { useSalesFeedScrolling } from './ui/useSalesFeedScrolling';
const getColumnCount = () => {
  if (window.innerWidth >= 1400) return [6, Math.min(1504, window.innerWidth) / 6 - 8];
  if (window.innerWidth >= 1200) return [5, Math.min(1504, window.innerWidth) / 5 - 8];
  if (window.innerWidth >= 992) return [4, Math.min(1504, window.innerWidth) / 4 - 8];
  if (window.innerWidth >= 768) return [3, Math.min(1504, window.innerWidth) / 3 - 8];
  if (window.innerWidth >= 576) return [3, Math.min(1504, window.innerWidth) / 3 - 8];
  return [2, Math.min(1504, window.innerWidth) / 2 - 8];
};
Math.max(500, window.innerWidth)
const SalesFeed = ({ sales = [], type, targetArticle }) => {
  const { theme } = useTheme();
  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';
  const { fetchMoreSales } = useWebSocket();

  // Локальный стейт для модалки:
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  // Считаем начальный columnCount по умолчанию:
  const [columnCount, setColumnCount] = useState(
    type === 'ws'
      ? getColumnCount()
      : [2, Math.min(500, window.innerWidth) / 2 - 30]
  );

  // Вешаем слушатель resize, чтобы пересчитывать columnCount
  const handleResize = useCallback(() => {
    setColumnCount(
      type === 'ws'
        ? getColumnCount()
        : [2, Math.min(500, window.innerWidth) / 2 - 30]
    );
  }, [type]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // первым делом посчитать
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  /**
   * Подключаем наш кастомный хук для работы со скроллом
   */
  const {
    listRef,
    unseenCount,
    handleScroll,
    scrollToNewestMessage,
    isAutoScrolling
  } = useSalesFeedScrolling({
    sales,
    fetchMoreSales,
    type,
    targetArticle,
    columnCount,
    rowHeight: 430,
  });

  // Открытие/закрытие модалки с картинками
  const openModal = (images) => {
    setModalImages(images);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalImages([]);
  };

  // Слайдер картинок в модалке
  const renderImagesSlider = () => (
    <Modal show={modalVisible} onHide={closeModal} centered>
      <Modal.Body className={themeClass} style={{ borderRadius: '10px' }}>
        <Carousel interval={2000} controls indicators pause="hover" wrap>
          {modalImages.map((image, index) => (
            <Carousel.Item key={index}>
              <img className="d-block w-100" src={image.replace("c246x328", "c516x688")} alt={`Slide ${index}`} style={{ borderRadius: '10px' }} />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );

  // Декодирование HTML-сущностей
  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  // Пример компонента со звёздочками рейтинга
  const RatingStars = ({ rating, feedbacks }) => {
    const stars = [1, 2, 3, 4, 5].map((star) =>
      rating < star
        ? <Star key={star} size={14} color="orange" style={{ marginTop: 1 }} />
        : <StarFill key={star} size={14} color="orange" style={{ marginTop: 1 }} />
    );

    return (
      <div
        style={{
          display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
          backgroundColor: '#fff', borderRadius: 10, height: 21,
          alignItems: 'center', position: 'absolute', top: -10, right: 0
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '4px', paddingRight: '4px', paddingBottom: '3px' }}>
          {stars}
        </div>
        <span style={{ color: 'orange', marginRight: 5, fontSize: 12, fontWeight: '400' }}> | {feedbacks}</span>
      </div>
    );
  };

  // Пример компонента с временем
  const Time = ({ ts }) => {
    const date = new Date(ts * 1000);
    const hours = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return <div className="time-style">{formattedTime}</div>;
  };

  // AIValue, SkaldObr, Diapaz, MarketType, MarketTypeButton, parseSizes
  // оставляю как есть — это твои вспомогательные компоненты/функции

  const AIValue = ({ ai, price, price2 }) => {
    const aiInt = parseInt(ai, 10);
    const bgColor =
      price2 > price ? 'rgb(242, 73, 66)' :
        price2 < price ? 'rgb(52, 152, 219)' :
          'rgb(101, 211, 73)';
    return (
      <div className="ai-style" style={{ backgroundColor: bgColor }}>
        AI: {aiInt}%
      </div>
    );
  };

  function Diapaz(diapazmin, diapazmax) {
    let mind = diapazmin == null ? 0 : diapazmin.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', maximumSignificantDigits: 21 });
    let maxd = diapazmax == null ? 0 : diapazmax.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', maximumSignificantDigits: 21 });
    let diapaz = `${mind} - ${maxd}`;
    if (mind === 0 && maxd === 0) {
      diapaz = null;
    }
    return diapaz;
  }

  const MarketType = ({ marketplace }) => {
    let marketColor;
    if (marketplace === 'wb') marketColor = '#8A2BE2';
    else if (marketplace === 'ozon') marketColor = '#007FFF';
    else marketColor = "#00C2FC";

    return (
      <div
        style={{
          display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
          backgroundColor: marketColor, borderRadius: 10, height: 21,
          alignItems: 'center', position: 'absolute', top: -10, left: 0
        }}
      >
        <span style={{ color: 'white', marginLeft: 5, marginRight: 5, fontSize: 12, fontWeight: '600' }}>
          {marketplace.toUpperCase()}
        </span>
      </div>
    );
  };

  const MarketTypeButton = ({ marketplace, article }) => {
    if (marketplace === 'wb') {
      return (
        <Button
          style={{ backgroundColor: '#8A2BE2', border: 'none' }}
          href={`https://www.wildberries.ru/catalog/${article}/detail.aspx`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Перейти
        </Button>
      );
    } else if (marketplace === 'ozon') {
      return (
        <Button
          style={{ backgroundColor: '#007FFF', border: 'none' }}
          href={`https://ozon.ru/products/${article}/`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Перейти
        </Button>
      );
    } else {
      return (
        <Button
          style={{ backgroundColor: "#00C2FC", border: 'none' }}
          href={`https://www.detmir.ru/product/index/id/${article}/`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Перейти
        </Button>
      );
    }
  };

  const SkaldObr = ({ sklad, skladR }) => {
    const cleanString = (str) => {
      if (typeof str !== 'string') return 'un';
      return str
        .replace(/<b>/g, '')
        .replace(/<\/b>/g, '')
        .replace(/😍 /g, '')
        .replace(/⚠️ /g, '')
        .replace(/шт\./g, '')
        .replace(/WB - /g, '')
        .replace(/ПРОД - /g, '');
    };

    if (!skladR || skladR.trim() === '') {
      skladR = '0';
    }

    let chek_sklad = sklad ? sklad.split(' | ') : [];
    let chek_sklad2 = skladR.split(' | ');
    let formattedSklad1 = '';
    let formattedSklad2 = '';

    if (chek_sklad2.length > 1) {
      formattedSklad1 = `${cleanString(chek_sklad2[0])}/${cleanString(chek_sklad[0])}`;
      formattedSklad2 = `${cleanString(chek_sklad2[1])}/${cleanString(chek_sklad[1])}`;
    } else {
      formattedSklad1 = `${cleanString(chek_sklad2[0])}/${cleanString(chek_sklad[0])}`;
    }

    const getBadgeColor = (str) => {
      if (str && str.includes('WB - ')) return 'green';
      else if (str && str.includes('ПРОД - ')) return 'orange';
      return '';
    };

    return (
      <>
        {chek_sklad.length > 1 ? (
          <div className="stock-container">
            <p className={`stock-badge ${getBadgeColor(chek_sklad[0])}`}>
              {formattedSklad1}
            </p>
            <p className={`stock-badge ${getBadgeColor(chek_sklad[1])}`}>
              {formattedSklad2}
            </p>
          </div>
        ) : (
          <p className={`single-stock-badge ${getBadgeColor(chek_sklad[0])}`}>
            {formattedSklad1}
          </p>
        )}
      </>
    );
  };

  const parseSizes = (sizesString) => {
    if (!sizesString || typeof sizesString !== 'string') return [];
    return sizesString.split('|').map((item) => {
      const trimmed = item.trim();
      const isW = trimmed.startsWith('(W)');
      const isP = trimmed.startsWith('(P)');
      const color = isW ? 'green' : isP ? 'orange' : 'gray';
      const text = trimmed.replace('(W)', '').replace('(P)', '').trim();
      return { color, text };
    });
  };

  // Компонент (ячейка) для одной карточки товара
  const Cell = React.memo(({ index, style, sale, themeClass, openModal, columnCount }) => {
    const sizesData = parseSizes(sale.sizes);

    return (
      <div
        key={sale.id}
        className="card-container"
        data-index={index}
        style={{ ...style, padding: '10px 0px 10px 15px' }}
      >
        <Card
          className={`mb-4 ${themeClass} sale-card`}
          style={{
            opacity: sale.col2 < 1 && sale.marketplace === 'wb' ? '0.5' : '1'
          }}
        >
          <div
            className="image-container"
            onClick={() => openModal(sale.linkphoto.split(','))}
          >
            <Card.Img
              variant="top"
              loading="lazy"
              src={sale.linkphoto.split(',')[0]}
              className="sale-image"
            />
            <Time ts={sale.ts} />
            {sale.marketplace === 'wb' && sale.ai >= 0 && (
              <AIValue ai={sale.ai} price={sale.price} price2={sale.price2} />
            )}
            {sale.sklad && <SkaldObr sklad={sale.sklad} skladR={sale.sklad2} />}

            {sale.col2 !== 0 && sizesData.length > 0 && (
              <div
                className="sizes-scroll"
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: '2px',
                  padding: '2px'
                }}
              >
                {sizesData.map((item, idx) => (
                  <span
                    key={idx}
                    style={{
                      backgroundColor:
                        item.color === 'green'
                          ? 'rgba(0,128,0,0.5)'
                          : item.color === 'orange'
                            ? 'rgba(255,165,0,0.5)'
                            : 'rgba(102,102,102,0.5)',
                      color: '#fff',
                      padding: '1px 3px',
                      borderRadius: '4px',
                      fontSize: '10px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {item.text}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="sale-content">
            <div className="price-container">
              <p
                className="price-text"
                style={{
                  backgroundColor:
                    sale.marketplace === 'wb'
                      ? sale.price2 > sale.price
                        ? '#f24942'
                        : sale.price2 < sale.price
                          ? '#3498db'
                          : '#65d349'
                      : '#65d349'
                }}
              >
                {sale.marketplace === 'wb' && sale.price2
                  ? sale.price2.toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB',
                    maximumSignificantDigits: 21
                  })
                  : sale.price.toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB',
                    maximumSignificantDigits: 21
                  })}
              </p>
              <div className="price-details">
                <p
                  className={`diapaz-text ${Diapaz(sale.diapazmin, sale.diapazmax) == null
                    ? 'no-diapaz'
                    : ''
                    }`}
                >
                  {Diapaz(sale.diapazmin, sale.diapazmax) == null
                    ? 'Нет диапазона'
                    : Diapaz(sale.diapazmin, sale.diapazmax)}
                </p>
                <>
                  <div className="price-row">
                    <p style={{ marginBottom: 0 }}>
                      {sale.marketplace === 'wb' && sale.price2 > sale.price
                        ? '📈'
                        : sale.marketplace === 'wb' && sale.price2 < sale.price
                          ? '📉'
                          : ''}
                    </p>
                    <s className="s-price">
                      {sale.marketplace === 'wb' &&
                        sale.price2 &&
                        sale.price !== sale.price2
                        ? sale.price.toLocaleString('ru-RU', {
                          style: 'currency',
                          currency: 'RUB',
                          maximumSignificantDigits: 21
                        })
                        : ''}
                    </s>
                  </div>
                </>
              </div>
            </div>

            <p className={`sale-name ${themeClass}`}>
              {decodeHTMLEntities(sale.name)}
            </p>
            <MarketType marketplace={sale.marketplace} />
            {sale.marketplace === 'wb' && (
              <RatingStars rating={sale.rating} feedbacks={sale.feedbacks} />
            )}
            {sale.subject && (
              <div className="subject-style">
                {sale.subject.split(/(<code>.*?<\/code>)/g).map((part, index) =>
                  part.includes('<code>') ? (
                    <Codee>
                      {part.replace(/<\/?code>/g, '')}
                    </Codee>
                  ) : (
                    <span key={index}>
                      {part.length > 40 ? part.slice(0, 40) + '...' : part}
                    </span>
                  )
                )}
              </div>
            )}
            <MarketTypeButton marketplace={sale.marketplace} article={sale.article} />
          </div>
        </Card>
      </div>
    );
  });

  // cellRenderer из react-window
  const cellRenderer = useCallback(
    ({ columnIndex, rowIndex, style, data }) => {
      const { sales, themeClass, openModal, columnCount } = data;
      const index = rowIndex * columnCount[0] + columnIndex;
      if (index >= sales.length) return null;

      const sale = sales[index];
      return (
        <Cell
          index={index}
          style={style}
          sale={sale}
          themeClass={themeClass}
          openModal={openModal}
          columnCount={columnCount}
        />
      );
    },
    []
  );

  // Стили для Grid
  const typeStyle =
    type === 'ws'
      ? { overflowY: 'auto', height: 'auto', width: '80vh !important' }
      : { overflowY: 'auto', height: 'auto', width: '80vh !important' };

  return (
    <>
      <Grid
        columnCount={
          type === 'ws'
            ? columnCount[0]
            : sales.length === 1
              ? 1
              : columnCount[0]
        }
        columnWidth={columnCount[1]}
        height={window.innerHeight - 60}
        rowCount={Math.ceil(sales.length / columnCount[0])}
        rowHeight={430}
        width={type === 'ws' ? window.innerWidth : window.innerWidth * 0.8}
        itemData={{ sales, themeClass, openModal, columnCount }}
        children={cellRenderer}
        onScroll={type === 'ws' ? handleScroll : ''}
        ref={listRef}
        className={type === 'ws' ? 'scrollable' : ''}
        overscanRowCount={type === 'ws' ? 3 : 20}
        overscanColumnCount={type === 'ws' ? 2 : 20}
        style={typeStyle}
      />

      {renderImagesSlider()}

      {!isAutoScrolling && (
        <div className="scroll-to-bottom-container">
          {unseenCount > 0 && (
            <Badge pill bg="primary" className="unseen-count-badge">
              {unseenCount}
            </Badge>
          )}

          <div className="scroll-to-bottom-button" onClick={scrollToNewestMessage}>
            <Lottie
              animationData={scrollDownAnimation}
              loop={false}
              initialSegment={[0, 70]}
              speed={2}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SalesFeed;
