import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import BottomNav from '../BottomNav';
import { useTheme } from '../../ThemeContext';
import { useAuth } from '../AuthContext'; // Импортируй свой AuthContext
import { PlusCircle } from 'react-bootstrap-icons';

const WaitingListPage = () => {
  const { theme } = useTheme();
  const { userInfo, isAuthenticated, isLoading, createTrackingItem, updateTrackingItem, getTrackingList, deleteTrackingItem, parseTrackingItem, trackingItems } = useAuth();

  const [logOutput, setLogOutput] = useState('');
  const [trackingList, setTrackingList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';

  const headerStyle = {
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: '0.5rem',
  };

  if (userInfo.user_id !== 7017255909) {
    return <div className={themeClass}>Скоро будет, уже работаем на полную</div>;
  }

  // Загружаем список отслеживаемых товаров при монтировании компонента
  useEffect(() => {
    const fetchTrackingList = async () => {
      if (isAuthenticated && !isLoading) {
        getTrackingList();
      }
    };

    fetchTrackingList();
  }, []);


  // Пока userInfo не загрузилось, можно показывать "загрузка..."


  // Если это тот самый user_id, рендерим "особый" вариант
  if (userInfo.user_id === 7017255909) {
    return (
      <>
        <Container className={`pt-3 pb-5 ${themeClass}`}>
          <Row className="mb-3">
            <Col>
              <div className={`profile-header rounded ${themeClass} shadow-lg`} style={headerStyle}>
                <h1 className="text-center">
                  Лист Отслеживания 🛒
                </h1>
              </div>
            </Col>
          </Row>

          {/* Тут в будущем будет блок отображения списка товаров trackingItems */}
          {trackingItems.length > 0 && (<Row className="mb-3">
            <Col>
              <div className={`d-flex rounded ${themeClass} shadow-lg`} style={headerStyle}>
                {
                  /* 
                    Пример (заготовка) рендера списка:
                    trackingItems.map((item, index) => (
                      <div key={index}>{item.title}</div>
                    ))
                  */
                }
              </div>
            </Col>
          </Row>)}

          {/* Проверяем, сколько товаров в списке, и если < 30, показываем кнопку добавления */}
          {trackingItems.length < 30 && (
            <Row className="mb-3">
              <Col>
                <div
                  className={`d-flex align-items-center justify-content-center gap-2 rounded p-3 shadow-lg`}
                  style={{
                    cursor: 'pointer',
                    border: theme === 'dark' ? '1px solid #444' : '1px solid #ddd',
                    backgroundColor: theme === 'dark' ? '#333' : '#f8f9fa',
                    color: theme === 'dark' ? '#fff' : '#000',
                    fontSize: '1.25rem',
                    fontWeight: '500',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = theme === 'dark' ? '#444' : '#e9ecef';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = theme === 'dark' ? '#333' : '#f8f9fa';
                  }}
                  onClick={() => setShowModal(true)}
                >
                  <span>Добавить товар</span>
                  <PlusCircle size={24} color={theme === 'dark' ? '#fff' : '#007bff'} />
                </div>
              </Col>
            </Row>
          )}

        </Container>

        {/* Модалка для добавления нового товара */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Добавить новый товар</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Здесь будет твоя форма или любая другая логика</p>
            <p>Пока что просто текст</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>

        <BottomNav />
      </>
    );
  }

  // Если user_id не 7017255909, можно вернуть что-то другое или заглушку

};

export default WaitingListPage;
